import React from "react";
import NavBar from "./NavBar.js";
import HeaderSVG from "../../images/svgFiles/HeaderSVG.js";

export default ({ data, children }) => (
  <header className="leading-normal tracking-normal text-white gradient">
    <NavBar />
    {children}
    
    <div className="pt-6">
      <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
          <br></br>
          <br></br>
        </div>
      </div>
    </div>

    {/* Waves SVG */}
    <div className="relative -mt-12 lg:-mt-24">
      <HeaderSVG />
    </div>
  </header>
);
